import React, { useState } from 'react';
import styled from '@emotion/styled';
import Button from 'components/button/Button';
import TextField from 'components/TextField';
import { H2 } from 'components/typography';
import { useAuthContext } from 'contexts/AuthContext';

// states = ['init', 'signup', 'signin']

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 360px;
  margin: 30px auto;
  padding: 32px 16px;
  border-radius: ${({ theme }) => theme.borderRadiusM};
  background: ${({ theme }) => theme.palette.WHITE}; ;
`;

const getTitle = (state) => {
  switch (state) {
    case 'signup':
      return 'Skapa konto!';
    case 'signin':
    default:
      return 'Logga in!';
  }
};

const getButtonText = (state) => {
  switch (state) {
    case 'signup':
      return 'REGISTRERA';
    case 'signin':
      return 'LOGGA IN';
    default:
      return 'GÅ VIDARE';
  }
};

const Login = () => {
  const { emailExist, login, signup, resetPassword } = useAuthContext();
  const [state, setState] = useState('init');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pwreset, setPwreset] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) return setErrMsg('Ange email address');
    if (state !== 'init' && !password) return setErrMsg('Ange lösenord');
    setErrMsg('');

    setLoginLoading(true);
    try {
      switch (state) {
        case 'init': {
          const exist = await emailExist(email);
          if (exist) {
            setLoginLoading(false);
            setState('signin');
            return;
          }
          setLoginLoading(false);
          setState('signup');
          return;
        }
        case 'signin': {
          return login(email, password, () => setLoginLoading(false));
        }
        case 'signup':
          return signup(email, password);
        default:
          return;
      }
    } catch (e) {
      setLoginLoading(false);
    }
  };

  const handlePasswordReset = async () => {
    if (!email) return setErrMsg('Ange email address');
    setErrMsg('');

    await resetPassword(email);
    setPwreset(true);
  };

  return (
    <Wrapper>
      <H2>{getTitle(state)}</H2>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          id="email"
          label="E-mail"
          margin="normal"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {state !== 'init' && (
          <TextField
            autoFocus
            fullWidth
            id="password"
            label="Password"
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        )}
        {errMsg && <span>{errMsg}</span>}
        <Button
          fullWidth
          variant="contained"
          type="submit"
          style={{ marginTop: 20 }}
          disabled={loginLoading}
        >
          {getButtonText(state)}
        </Button>
        {state === 'signin' && (
          <>
            {pwreset ? (
              <p
                style={{
                  textAlign: 'center',
                  margin: '10px 0px 0px',
                  padding: '0px 10px'
                }}
              >
                Instruktioner för att återställa ditt lösenord har skickats till
                din email adress.
              </p>
            ) : (
              <Button
                fullWidth
                onClick={handlePasswordReset}
                style={{ fontSize: 14 }}
              >
                Glömt lösenord?
              </Button>
            )}
          </>
        )}
        {state === 'signup' && (
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              setState('init');
              setErrMsg('');
            }}
          >
            AVBRYT
          </Button>
        )}
      </form>
    </Wrapper>
  );
};

export default Login;
